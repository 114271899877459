import axios from "axios";

const resourcesWhitelistPattern = '/api/';

export const axiosWrapper = axios.create();
axios.interceptors.request.use(
    (config) => {
        if (config.url && config.url.startsWith(resourcesWhitelistPattern)) {
            return config;
        }

        return Promise.reject(new Error('URL not allowed or invalid'));
    },
    (error) => {
        return Promise.reject(error);
    }
);