import { XCircleIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import DeleteNotificationModal from "./DeleteNotificationModal";
import EditNotificationModal from "./EditNotificationModal";

type Data = {
  satelliteNotificationId: number;
  paginationId: number;
  satelliteId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  percentBelow: number;
  satellite: {
    satelliteName: string;
    satelliteCode: string;
  };
};
type PaginationProps = {
  currentPage: number;
  totalPages: number;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  handlePageChange: (page: number) => void;
};

const PAGE_RANGE_DISPLAYED = 5;



const CustomMaterialPagination = ({
  currentPage,
  totalPages,
  handlePreviousPage,
  handleNextPage,
  handlePageChange,
}: PaginationProps) => {
  const startPage = Math.max(
    1,
    currentPage - Math.floor(PAGE_RANGE_DISPLAYED / 2)
  );
  const endPage = Math.min(totalPages, startPage + PAGE_RANGE_DISPLAYED - 1);
  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  return (
    <div className="flex justify-start mt-5 mb-6 ml-4 space-x-3 text-sky-600">
      <button disabled={currentPage <= 1} onClick={handlePreviousPage}>
        Previous
      </button>
      {startPage > 1 && (
        <>
          <button onClick={() => handlePageChange(1)}>1</button>
          <span>...</span>
        </>
      )}
      {pages.map((page) => (
        <button
          key={page}
          className={page === currentPage ? "text-slate-900" : ""}
          disabled={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      ))}
      {endPage < totalPages && (
        <>
          <span>...</span>
          <button onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        </>
      )}
      <button disabled={currentPage >= totalPages} onClick={handleNextPage}>
        Next
      </button>
    </div>
  );
};

const NotificationsTable = ({ companyId, clientId, userId, satelliteId, refreshCount }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSatellite, setSelectedSatellite] = useState(null);
  const [satellitesData, setSatellitesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //pageItems
  const [itemsPerPage, setPageItems] = useState(20);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState(null);
  const [sort, setSort] = useState<string | null>(null);


  const handleSort = (column: any, sortDirection: any) => {
    if (column?.name) {
      let columnName = column.name.toLowerCase().split(" ").join("_");
      setSort(`${columnName}_${sortDirection}`);
    }
  };

  const handleOpenEditModal = (data: any) => {
    setSelectedForEdit({
      ...data,
      satelliteNotificationId: data.satelliteNotificationId,
      satelliteCode: data.satellite.satelliteCode,
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedForEdit(null);
    setEditModalOpen(false);
  };
  
  const fetchData = async (
    companyId: string,
    itemsPerPage: number,
    currentPage: number,
    sort: string | null,
    selectedSatelliteId?: number // passing selectedSatelliteId as a parameter
  ) => {
    setIsLoading(true);
  
    try {
      const response = await axios.get("/api/SatelliteNotification", {
        params: {
          clientId: clientId,
          companyId,
          Page: currentPage,
          ItemsPerPage: itemsPerPage,
          SortBy: sort,
          ...(selectedSatelliteId && { SatelliteId: selectedSatelliteId }), 
        },
      });
      const paginationData = JSON.parse(response.headers["x-pagination"]);
      setTotalPages(paginationData.TotalPages);
  
      setSatellitesData(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchData(companyId, itemsPerPage, currentPage, sort, satelliteId);
  }, [companyId, itemsPerPage, currentPage, sort, satelliteId, refreshCount]);
  


  const handleOpenModal = (data: any) => {
    setSelectedSatellite(data);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedSatellite(null);
    setModalOpen(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

    const isWhitelistedURL = (url: string): boolean => {
        if (url === '/api/SatelliteNotification/') {
            return true;
        }

        const satelliteNotificationRegex = /^\/api\/SatelliteNotification\/\d+$/;
        return satelliteNotificationRegex.test(url);
    };

    const handleDeleteSatellite = async (id: any, callback: () => void) => {
        setDeleteLoading(true);
        try {
            const url = `/api/SatelliteNotification/${id}`;

            if (isWhitelistedURL(url)) {
                const response = await axios.delete(url);
                refreshDataAfterUpdate();
                callback();
            } else {
                throw new Error('URL not allowed or invalid');
            }
        } catch (error) {
            console.error(`Error deleting satellite with id: ${id}`, error);
        } finally {
            setDeleteLoading(false);
        }
    };


  // Pagination handlers
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  if (isLoading) return <p>Loading...</p>; // Add a loading state

  const columns: TableColumn<Data>[] = [
    {
      name: "Satellite Code",
      selector: (row) => row.satellite.satelliteCode,
      sortable: true,
    },
    {
      name: "Satellite Name",
      selector: (row) => row.satellite.satelliteName,
      sortable: true,
      sortField: "satellite.satelliteName",
    },
    { name: "First Name", selector: (row) => row.firstName, sortable: true },
    { name: "Last Name", selector: (row) => row.lastName, sortable: true },
    { name: "Email", selector: (row) => row.emailAddress, sortable: true },
    {
      name: "Percent Below",
      selector: (row) => row.percentBelow,
      sortable: true,
    },
    {
      name: "Options",
      cell: (row: Data) => (
        <>
          <button
            className="mr-2 text-sky-600 hover:text-blue-900"
            onClick={() => handleOpenEditModal(row)}
          >
            <PencilSquareIcon className="w-5 h-5" />
          </button>
          <button
            className="text-sky-600 hover:text-red-900"
            onClick={() => handleOpenModal(row)}
          >
            <XCircleIcon className="w-5 h-5" />
          </button>
        </>
      ),
    },
  ];

  const refreshDataAfterUpdate = () => {
    fetchData(companyId, itemsPerPage, currentPage, sort, satelliteId);
  };
  

  return (
    <div className="flex flex-col">
      <DeleteNotificationModal
        isOpen={modalOpen}
        data={selectedSatellite}
        onClose={handleCloseModal}
        onDelete={handleDeleteSatellite}
        deleteLoading={deleteLoading}
      />

      <EditNotificationModal
        isOpen={editModalOpen}
        data={selectedForEdit}
        onClose={handleCloseEditModal}
        onUpdate={refreshDataAfterUpdate} 
        companyId={companyId}
        userId={userId}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        sort={sort}
      />

      <DataTable
        columns={columns}
        data={satellitesData}
        pagination
        paginationPerPage={itemsPerPage}
        onSort={handleSort}
        sortServer
        paginationComponent={() => (
          <CustomMaterialPagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
          />
        )}
        paginationServer
      />
    </div>
  );
};

export default NotificationsTable;
