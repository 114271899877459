import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./app";
import ReactGA from 'react-ga';
import axios from 'axios';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const TRACKING_ID = 'UA-41544088-5';
ReactGA.initialize(
    [
        {
            trackingId: 'UA-41544088-5',
        },
        {
            trackingId: 'UA-41544088-1',
            gaOptions: { name: 'masterTracker' }
        },
        {
            trackingId: 'UA-41544088-2'
        },
        {
            trackingId: 'UA-41544088-4',
            gaOptions: { name: 'four' }
        }
    ],
    { debug: false, alwaysSendToDefaultTracker: false }
);

axios.interceptors.request.use(
    (config) => {
        if (config.url && config.url.startsWith('/api/')) {
            return config;
        }

        return Promise.reject(new Error('URL not allowed or invalid'));
    },
    (error) => {
        return Promise.reject(error);
    }
);

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

